.dialog-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.dialog-modal {
  max-height: 90%;

  @media (max-width: 700px) {
    max-height: 100%;
  }

  overflow: auto;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  &.sm {
    width: 300px;
    inset: unset;
  }

  &.md {
    width: 500px;
    inset: unset;
  }

  &.lg {
    max-width: 1200px;
    inset: unset;
  }

  &.xl {
    inset: 40px;
    padding: 1.5rem;
  }

  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    inset: 0px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff;

    .modal-title {
      font-size: 20px;
      line-height: 38px;
      font-weight: bold;
      color: #444444;
    }

    .modal-close-btn {
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 24px;

      color: #000000;
      opacity: 0.5;
      transition: all 100 ease-in-out;

      &:hover {
        opacity: 1;
      }

      &:active {
        outline: none;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid #ffffff;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: start;
    padding: 20px;
    border: unset !important;
  }
}

.file-viewer-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  inset: 0px;
}

.custom-modal {
  width: 500px;
  height: 500px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 100 ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
