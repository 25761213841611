//
// _badge.scss
//


.badge {
  &[href] {

    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {

    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color,
$value in $theme-colors {
  .badge {
    &.bg-#{$color} {
      &[href] {

        &:hover,
        &:focus {
          background-color: darken($value, 4%) !important;
        }
      }
    }
  }

  .badge {
    &.bg-light {
      color: $body-color;

      &[href] {

        &:hover,
        &:focus {
          color: $body-color;
        }
      }
    }
  }

  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }

}

.rounded-pill {
  padding-right: .6em;
  padding-left: .6em;
}

.rounded-pill.timeslot {
  min-width: 120px;
  width: 100%;
  color: $gray-600;
  border: $gray-500 solid 1px;
  letter-spacing: 0.1px;

  &.active {
    background: $blue;
    color: white;
  }

  &.disabled{
    background: $gray-200;
  }
}

// Dark badge

.badge.bg-dark {
  color: $light;
}
