.preview-image {
  cursor: pointer;
}

.auto-complete-select-container {
  .form-group {
    display: flex;
    flex-direction: column;
  }

  .auto-complete {

    .dropdown-toggle-div{
      .cross-btn-div {
        position: absolute;
        right: 12px;
        bottom: 5px;
        font-weight: bold;
        cursor: pointer;

        i {
          font-size: 17px;
        }
      }

      .arrow-down-btn-div {
        opacity: 0.3;
        position: absolute;
        right: 10px;
        bottom: 2px;
        font-weight: bold;
        cursor: pointer;

        i {
          font-size: 25px;
        }
      }
    }



    .dropdown-menu {
      padding: 0px;
      background-color: white;
      z-index: 1;

      .drop-down-menu {
        height: 260px;
        overflow-y: scroll;
        border: 1px solid #ced4da;
        border-radius: 3px;

        .drop-down-option-item {
          padding: 9px 20px;
          cursor: pointer;

          &:hover {
            background-color: #556ee630;
          }

          &.selected {
            background-color: #556ee6;
            color: white;
          }
        }

        .no-option-text {
          padding: 9px 20px;
          color: rgba(0, 0, 0, 0.5);
          text-align: center;
          font-weight: 700;
        }
      }
    }
    .drop-down-card {
      width: 100%;
      margin-bottom: 0px;

      .drop-down-card-body {
        padding: 8px 0px;
      }
    }
  }
}

.selec-filter-loading {
  top: 50%;
  right: 8px;
  transform: translate(-50%, -50%);
  position: absolute;
}

