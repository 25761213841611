@import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

.calendar-container {
  .mbsc-calendar {
    .mbsc-calendar-wrapper {
      .mbsc-calendar-header {
        button {
          color: $blue
        }
      }

      .mbsc-calendar-body {
        aspect-ratio: 3/2;

        @media (max-width: 700px) {
          aspect-ratio: auto;
        }

        .mbsc-calendar-scroll-wrapper {
          .mbsc-selected {
            .mbsc-calendar-cell-text {
              background: $blue;
              border-color: $blue;
              color: white
            }
          }

          .mbsc-calendar-today {
            color: $blue
          }
        }

        .mbsc-calendar-mark {
          background: $yellow;
        }

        .mbsc-calendar-month-name {
          display: none
        }
      }
    }
  }
}
