.preview-image {
  cursor: pointer;
}

.auto-complete {
  .dropdown-menu {
    padding: 0px;
    background-color: white;
    z-index: 1;

    .drop-down-menu {
      height: 260px;
      overflow-y: scroll;
      border: 1px solid #ced4da;
      border-radius: 3px;

      .drop-down-option-item {
        padding: 9px 20px;
        cursor: pointer;

        &:hover {
          background-color: #556ee630;
        }

        &.selected {
          background-color: #556ee6;
          color: white;
        }
      }

      .no-option-text {
        padding: 9px 20px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        font-weight: 700;
      }
    }
  }
  .drop-down-card {
    width: 100%;
    margin-bottom: 0px;

    .drop-down-card-body {
      padding: 8px 0px;
    }
  }
}

.html-editor {
  min-height: 462px;
  margin-bottom: 8px;

  .quill  {

    .ql-toolbar.ql-snow {
      border: 1px solid #ced4da;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }


    .ql-container.ql-snow {
      min-height: 400px;
      max-height: 1000px;
      overflow-y: auto;
      border: 1px solid #ced4da;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;

      .ql-editor {
        min-height: 400px;
      }
    }

    .ql-tooltip {
      left: 0!important;
    }

    .ql-snow {
      .ql-picker {
          &.ql-size {
              .ql-picker-label,
              .ql-picker-item{
                  &::before{
                    content: attr(data-value) !important;
                  }
              }
          }
          &.ql-lineHeight {
            width: 98px;

            .ql-picker-label, .ql-picker-item {
              &::before {
                content: attr(data-value) !important;
              }
              &[data-value="1"] {
                &::before {
                  content: '單行' !important;
                }
              }
              &[data-value="2"] {
                &::before {
                  content: '雙行' !important;
                }
              }
            }
          }
      }
    }
  }

  &.dark {
    .quill {
      .ql-container {
        background: #202124;
      }
    }
  }
}



