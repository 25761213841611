.user-row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 6px;
  border-radius: 2px;

  &:hover {
    background-color: rgba(85, 110, 230, 0.1) !important;
  }
}
